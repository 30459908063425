




























import _3did from "@/api/3did";
import { Component, Vue, Prop } from "vue-property-decorator";

type Format = "obj" | "csv";

@Component
export default class TableRow extends Vue {
  @Prop({ default: "" }) date!: string;
  @Prop({ default: "" }) email!: string;
  @Prop({ default: "" }) username!: string;
  @Prop({ default: "" }) scanId!: string;

  private api = new _3did();

  private downloading: { [format in Format]: boolean } = {
    obj: false,
    csv: false
  };

  async downloadData(format: Format) {
    this.downloading[format] = true;

    const prefix = this.email + "_" + this.formatDateForFile(this.date) + "-";

    try {
      var resp;
      if (format === "obj") {
        resp = await this.api.bodyMesh(this.scanId, prefix);
      } else if (format === "csv") {
        resp = await this.api.csv(this.scanId, prefix);
      } else {
        return;
      }

      window.location.assign(resp.output.url);
    } finally {
      this.downloading[format] = false;
    }
  }

  formatDate(date: string): string {
    let options: Intl.DateTimeFormatOptions = {
      hour12: true,
      hour: "2-digit",
      minute: "2-digit"
    };
    return new Date(date).toLocaleDateString("en-us", options);
  }

  formatDateForFile(dateString: string): string {
    const date = new Date(dateString);

    return [
      date.getFullYear(),
      (date.getMonth() + 1).toString().padStart(2, "0"),
      date
        .getDate()
        .toString()
        .padStart(2, "0"),
      (date.getHours() % 12 ? date.getHours() % 12 : 12)
        .toString()
        .padStart(2, "0"),
      date
        .getMinutes()
        .toString()
        .padStart(2, "0"),
      date
        .getSeconds()
        .toString()
        .padStart(2, "0"),
      date.getHours() < 12 ? "AM" : "PM"
    ].join("_");
  }
}
