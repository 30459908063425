
















import { Component, Vue } from "vue-property-decorator";

import { Auth } from "aws-amplify";

@Component
export default class Menu extends Vue {
  async logout() {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }
}
