






























































import _3did from "@/api/3did";
import { Component, Vue } from "vue-property-decorator";

import TableRow from "../components/TableRow.vue";

interface TableItem {
  date: string;
  email: string;
  username: string;
  scanId: string;
}

@Component({
  components: {
    "table-row": TableRow
  }
})
export default class Home extends Vue {
  private api = new _3did();

  items: TableItem[] = [];
  loading = false;
  sortByColumn: string = "date";
  sortAsc = false;

  async refresh() {
    this.loading = true;

    let users = await this.api.users();

    if (users != null) {
      let usersItems: TableItem[][] = await Promise.all(
        users.map(
          async (user): Promise<TableItem[]> => {
            let scans = await this.api.scans(user.id);

            if (scans == null) return [];

            scans = scans.filter(scan => scan.status.startsWith("complete"));

            return await Promise.all(
              scans.map(
                (scan): TableItem => {
                  return {
                    date: scan.date,
                    email: user.email,
                    username: `${user.firstName || ""} ${user.middleName ||
                      ""} ${user.lastName || ""}`,
                    scanId: scan.id
                  };
                }
              )
            );
          }
        )
      );

      this.items = usersItems.flat();
    } else {
      this.items = [];
    }

    this.loading = false;
  }

  async mounted() {
    await this.refresh();
  }

  get sortedItems() {
    if (this.sortByColumn == "date") {
      let items = this.items.sort((a, b) => {
        if (a.date < b.date) {
          return -1;
        }
        if (a.date > b.date) {
          return 1;
        }
        return 0;
      });
      if (!this.sortAsc) {
        items = items.reverse();
      }
      return items;
    } else if (this.sortByColumn == "email") {
      let items = this.items.sort((a, b) => {
        if (a.email < b.email) {
          return -1;
        }
        if (a.email > b.email) {
          return 1;
        }
        return 0;
      });
      if (!this.sortAsc) {
        items = items.reverse();
      }
      return items;
    } else {
      return this.items;
    }
  }

  sortBy(col: string) {
    if (this.sortByColumn == col) {
      this.sortAsc = !this.sortAsc;
    } else {
      this.sortAsc = false;
    }
    this.sortByColumn = col;
  }
}
