import UserModel from "./models/user";
import ScanModel from "./models/scan";
import DataUrlModel from "./models/data";

import { API } from "aws-amplify";

export default class ThreeDIdApi {
  protected api: string = "3did";

  public async users(): Promise<UserModel[] | null> {
    return await API.get(this.api, "/users", {});
  }

  public async scans(userId: string): Promise<ScanModel[] | null> {
    return await API.get(this.api, `/users/${userId}/scans`, {});
  }

  public async bodyMesh(scanId: string, prefix = ""): Promise<DataUrlModel> {
    return await API.get(this.api, `/scans/${scanId}/data/body-mesh/1`, {
      headers: {
        "file-prefix": prefix
      }
    });
  }

  public async csv(scanId: string, prefix = ""): Promise<DataUrlModel> {
    return await API.get(this.api, `/scans/${scanId}/data/core-measures/1`, {
      queryStringParameters: {
        format: "text/csv"
      },
      headers: {
        "file-prefix": prefix
      }
    });
  }
}
